// import React, { useState } from "react";

// const GlobalContext = React.createContext();

// const GlobalProvider = ({ children }) => {
//   const [themeDark, setThemeDark] = useState(false);
//   const [showSidebarDashboard, setShowSidebarDashboard] = useState(true);
//   const [applicationModalVisible, setApplicationModalVisible] = useState(false);
//   const [signInModalVisible, setSignInModalVisible] = useState(false);
//   const [signUpModalVisible, setSignUpModalVisible] = useState(false);
//   const [videoModalVisible, setVideoModalVisible] = useState(false);
//   const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
//   const [user, setUser] = useState(null); // Track the user state
//   const [header, setHeader] = useState({
//     theme: "light",
//     bgClass: "default",
//     variant: "primary",
//     align: "left",
//     isFluid: false,
//     button: "cta", // profile, account, null
//     buttonText: "Get started free", // profile, account, null
//     reveal: true,
//   });
//   const [footer, setFooter] = useState({
//     theme: "dark",
//     style: "style1", //style1, style2
//   });

//   const toggleTheme = () => {
//     setThemeDark(!themeDark);
//   };

//   const toggleSidebarDashboard = () => {
//     setShowSidebarDashboard(!showSidebarDashboard);
//   };

//   const toggleVideoModal = () => {
//     setVideoModalVisible(!videoModalVisible);
//   };

//   const toggleApplicationModal = () => {
//     setApplicationModalVisible(!applicationModalVisible);
//   };

//   const toggleSignInModal = () => {
//     setSignInModalVisible(!signInModalVisible);
//   };

//   const toggleSignUpModal = () => {
//     setSignUpModalVisible(!signUpModalVisible);
//   };

//   const toggleOffCanvas = () => {
//     setVisibleOffCanvas(!visibleOffCanvas);
//   };

//   const closeOffCanvas = () => {
//     setVisibleOffCanvas(false);
//   };

//   return (
//     <GlobalContext.Provider
//       value={{
//         themeDark,
//         toggleTheme,
//         showSidebarDashboard,
//         toggleSidebarDashboard,
//         videoModalVisible,
//         toggleVideoModal,
//         applicationModalVisible,
//         toggleApplicationModal,
//         signInModalVisible,
//         toggleSignInModal,
//         signUpModalVisible,
//         toggleSignUpModal,
//         visibleOffCanvas,
//         toggleOffCanvas,
//         closeOffCanvas,
//         header,
//         setHeader,
//         footer,
//         setFooter,
//         user, // Provide user state
//         setUser, // Provide function to update user state
//       }}
//     >
//       {children}
//     </GlobalContext.Provider>
//   );
// };

// export default GlobalContext;
// export { GlobalProvider };


import React, { useEffect, useRef, useState } from "react";
import Cookies from 'js-cookie';
import network from "../../constants/Network";
import axios from "axios";

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false);
  const [showSidebarDashboard, setShowSidebarDashboard] = useState(true);
  const [applicationModalVisible, setApplicationModalVisible] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);
  const [otpVerifyModalVisible, setOtpVerifyModalVisible] = useState(false);
  const [mobNumVerifyModalVisible, setMobNumVerifyModalVisible] = useState(false);
  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [hrSignUpModalVisible, setHrSignUpModalVisible] = useState(false);
  const [signUpSelectionModalVisible, setSignUpSelectionModalVisible] = useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
  const [emailUpdateModalVisible, setEmailUpdateModalVisible] = useState(false);

  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
  const [userName, setUserName] = useState('');

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState(null);
  const [isJobProvider, setIsJobProvider] = useState(false);

  const isMountedRef = useRef(false);
  const [newJobsToday, setNewJobsToday] = useState(0);
  const [newCompaniesToday, setNewCompaniesToday] = useState(0);
  const [jobApplicantsCount, setJobApplicantsCount] = useState(0);

  const [isChatVisible, setChatVisible] = useState(true);
  const handleClose = () => setChatVisible(false);
  const handleOpenChat = () => setChatVisible(true);


  const [imageToDisplay, setImageToDisplay] = useState('');
  const [imageViewModalVisible, setImageViewModalVisible] = useState(false);



  useEffect(() => {
    isMountedRef.current = true;

    const fetchData = async () => {
      await fetchJobsCompaniesCount();
      await fetchJobApplicantsCount();
    };

    fetchData();
    return () => {
      isMountedRef.current = false;
    };
  }, [user]);

  const fetchJobsCompaniesCount = async () => {
    try {
      const response = await axios.get(`${network.serverip}/api/jobs-and-companies-count-web`);
      if (response.data.success && isMountedRef.current) {
        setNewJobsToday(response.data.newJobsToday);
        setNewCompaniesToday(response.data.newCompaniesToday);
        console.log('New jobs today:', response.data.newJobsToday);
        console.log('New companies today:', response.data.newCompaniesToday);
      } else if (!response.data.success) {
        console.error('Failed to fetch job and companies count');
      }
    } catch (error) {
      console.error('Error fetching job and companies count:', error);
    }
  };

  const fetchJobApplicantsCount = async () => {
    try {

      if (!user || !user.id) {
        console.error('User or user ID is not available');
        return;
      }

      const userId = user.id;

      const response = await axios.post(`${network.serverip}/api/job-applicants-count-web`, {
        userId: userId,
      });

      if (response.data.success && isMountedRef.current) {
        setJobApplicantsCount(response.data.jobApplicantsCount);
        console.log('Job applicants count:', response.data.jobApplicantsCount);
      } else if (!response.data.success) {
        console.error('Failed to fetch job applicants count');
      }
    } catch (error) {
      console.error('Error fetching job applicants count:', error);
    }
  };

  useEffect(() => {
    // const storedToken = sessionStorage.getItem('token');
    // const storedUser = sessionStorage.getItem('user');
    // const storedProfile = sessionStorage.getItem('profile');
    // const storedIsJobProvider = sessionStorage.getItem('isJobProvider');

    // Initialize user data from cookies
    const storedToken = Cookies.get('token');
    const storedUser = Cookies.get('user'); // Will be a JSON string or undefined
    const storedProfile = Cookies.get('profile'); // Will be a JSON string or undefined
    const storedIsJobProvider = Cookies.get('isJobProvider'); // Can be a string

    if (storedToken) {
      setToken(storedToken);
      console.log("Token in gContext:", storedToken);
    }
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      console.log("User in gContext:", parsedUser);
    }
    if (storedProfile) {
      const parsedProfile = JSON.parse(storedProfile);
      setProfile(parsedProfile);
      console.log("Profile in gContext:", parsedProfile);
    }
    // if (storedIsJobProvider) {
    //   const isProvider = storedIsJobProvider === 'true';
    //   setIsJobProvider(isProvider);
    //   console.log("Is Job Provider in gContext:", isProvider);
    // }
    if (storedIsJobProvider) {
      // Convert string to number explicitly
      const isProvider = Number(storedIsJobProvider);
      setIsJobProvider(isProvider);
      console.log("Is Job Provider in gContext (as number):", isProvider);
    }
  }, []);

  // useEffect(() => {
  //   // Function to check for inactivity and expire cookies
  //   let inactivityTimeout;

  //   const resetInactivityTimer = () => {
  //     // Clear any existing timeout
  //     clearTimeout(inactivityTimeout);

  //     // Set a new timeout to remove cookies after 30 minutes of inactivity
  //     inactivityTimeout = setTimeout(() => {
  //       // Remove cookies due to inactivity
  //       Cookies.remove('token');
  //       Cookies.remove('user');
  //       Cookies.remove('profile');
  //       Cookies.remove('isJobProvider');
  //       console.log('Cookies expired due to inactivity');
  //     }, 30 * 60 * 1000); // Convert minutes to milliseconds
  //   };

  //   // Attach event listeners to reset the inactivity timer on user activity
  //   const trackUserActivity = () => {
  //     window.addEventListener('mousemove', resetInactivityTimer);
  //     window.addEventListener('keydown', resetInactivityTimer);
  //     window.addEventListener('scroll', resetInactivityTimer);
  //   };

  //   // Initialize user data from cookies
  //   const storedToken = Cookies.get('token');
  //   const storedUser = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
  //   const storedProfile = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')) : null;
  //   const storedIsJobProvider = Cookies.get('isJobProvider');

  //   if (storedToken) {
  //     setToken(storedToken);
  //     console.log('Token in gContext:', storedToken);
  //   }
  //   if (storedUser) {
  //     const parsedUser = storedUser; // No need to parse since it's already an object
  //     setUser(parsedUser);
  //     console.log('User in gContext:', parsedUser);
  //   }
  //   if (storedProfile) {
  //     const parsedProfile = storedProfile; // No need to parse since it's already an object
  //     setProfile(parsedProfile);
  //     console.log('Profile in gContext:', parsedProfile);
  //   }
  //   if (storedIsJobProvider) {
  //     const isProvider = storedIsJobProvider === 'true';
  //     setIsJobProvider(isProvider);
  //     console.log('Is Job Provider in gContext:', isProvider);
  //   }

  //   // Start tracking user activity
  //   trackUserActivity();
  //   resetInactivityTimer(); // Initialize the inactivity timer

  //   // Clean up event listeners and timeout on component unmount
  //   return () => {
  //     clearTimeout(inactivityTimeout);
  //     window.removeEventListener('mousemove', resetInactivityTimer);
  //     window.removeEventListener('keydown', resetInactivityTimer);
  //     window.removeEventListener('scroll', resetInactivityTimer);
  //   };
  // }, []);

  const [header, setHeader] = useState({
    theme: "light",
    bgClass: "default",
    variant: "primary",
    align: "left",
    isFluid: false,
    button: "cta", // profile, account, null
    buttonText: "Get started free", // profile, account, null
    reveal: true,
  });
  const [footer, setFooter] = useState({
    theme: "dark",
    style: "style1", //style1, style2
  });

  const toggleTheme = () => {
    setThemeDark(!themeDark);
  };


  const toggleSidebarDashboard = () => {
    setShowSidebarDashboard(!showSidebarDashboard);
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleApplicationModal = () => {
    setApplicationModalVisible(!applicationModalVisible);
  };

  const toggleSignInModal = () => {
    setSignInModalVisible(!signInModalVisible);
  };

  const toggleSignUpModal = () => {
    setSignUpModalVisible(!signUpModalVisible);
  };

  const toggleOtpVerifyModal = () => {
    setOtpVerifyModalVisible(!otpVerifyModalVisible);
  };

  const toggleMobNumVerifyModal = () => {
    setMobNumVerifyModalVisible(!mobNumVerifyModalVisible);
  };

  const toggleHrSignUpModal = () => {
    setHrSignUpModalVisible(!hrSignUpModalVisible);
  };

  const toggleSignUpSelectionModal = () => {
    setSignUpSelectionModalVisible(!signUpSelectionModalVisible);
  };

  const toggleForgotPasswordModal = () => {
    setForgotPasswordModalVisible(!forgotPasswordModalVisible);
  };

  const toggleEmailUpdateModal = () => {
    setEmailUpdateModalVisible(!emailUpdateModalVisible);
  };

  const toggleImageViewModal = (image) => {
    setImageViewModalVisible(!imageViewModalVisible);
    if (!image) {
      setTimeout(() => {
        setImageToDisplay(image);
      }, 400);
    } else {
      setImageToDisplay(image);
    }
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };


  return (
    <GlobalContext.Provider
      value={{
        themeDark,
        toggleTheme,
        showSidebarDashboard,
        toggleSidebarDashboard,
        videoModalVisible,
        toggleVideoModal,
        applicationModalVisible,
        toggleApplicationModal,
        signInModalVisible,
        toggleSignInModal,
        mobNumVerifyModalVisible,
        toggleMobNumVerifyModal,
        otpVerifyModalVisible,
        toggleOtpVerifyModal,
        signUpModalVisible,
        toggleSignUpModal,
        hrSignUpModalVisible,
        toggleHrSignUpModal,
        signUpSelectionModalVisible,
        toggleSignUpSelectionModal,
        forgotPasswordModalVisible,
        toggleForgotPasswordModal,
        emailUpdateModalVisible,
        toggleEmailUpdateModal,
        imageToDisplay,
        setImageToDisplay,
        imageViewModalVisible,
        toggleImageViewModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
        header,
        setHeader,
        footer,
        setFooter,
        userName,
        setUserName,
        user, // Provide user state
        setUser, // Provide function to update user state
        profile,
        setProfile,
        token,
        setToken,
        isJobProvider,
        setIsJobProvider,
        newJobsToday,
        newCompaniesToday,
        jobApplicantsCount,
        handleClose,
        setChatVisible,
        handleOpenChat,
        isChatVisible
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
