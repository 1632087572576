// import React from "react";
// import { Link } from "gatsby";

// import imgP from "../../assets/image/l3/png/pro-img.png";

// const Sidebar = (props) => {
//   return (
//     <>
//       {/* <!-- Sidebar Start --> */}

//       <div {...props}>
//         <div className="pl-lg-5">
//           {/* <!-- Top Start --> */}
//           <div className="bg-white shadow-9 rounded-4">
//             <div className="px-5 py-11 text-center border-bottom border-mercury">
//               <Link to="/#" className="mb-4">
//                 <img className="circle-54" src={imgP} alt="" />
//               </Link>
//               <h4 className="mb-0">
//                 <Link
//                   to="/#"
//                   className="text-black-2 font-size-6 font-weight-semibold"
//                 >
//                   David Henricks
//                 </Link>
//               </h4>
//               <p className="mb-8">
//                 <Link to="/#" className="text-gray font-size-4">
//                   Product Designer
//                 </Link>
//               </p>
//               <div className="icon-link d-flex align-items-center justify-content-center flex-wrap">
//                 <Link
//                   to="/#"
//                   className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
//                 >
//                   <i className="fab fa-linkedin-in"></i>
//                 </Link>
//                 <Link
//                   to="/#"
//                   className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
//                 >
//                   <i className="fab fa-facebook-f"></i>
//                 </Link>
//                 <Link
//                   to="/#"
//                   className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
//                 >
//                   <i className="fab fa-twitter"></i>
//                 </Link>
//                 <Link
//                   to="/#"
//                   className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
//                 >
//                   <i className="fab fa-dribbble"></i>
//                 </Link>
//                 <Link
//                   to="/#"
//                   className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
//                 >
//                   <i className="fab fa-behance"></i>
//                 </Link>
//               </div>
//             </div>
//             {/* <!-- Top End --> */}
//             {/* <!-- Bottom Start --> */}
//             <div className="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">
//               <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>
//               {/* <!-- Single List --> */}
//               <div className="mb-7">
//                 <p className="font-size-4 mb-0">Location</p>
//                 <h5 className="font-size-4 font-weight-semibold mb-0 text-black-2 text-break">
//                   New York , USA
//                 </h5>
//               </div>
//               {/* <!-- Single List --> */}
//               {/* <!-- Single List --> */}
//               <div className="mb-7">
//                 <p className="font-size-4 mb-0">E-mail</p>
//                 <h5 className="font-size-4 font-weight-semibold mb-0">
//                   <a
//                     className="text-black-2 text-break"
//                     href="mailto:name_ac@gmail.com"
//                   >
//                     name_ac@gmail.com
//                   </a>
//                 </h5>
//               </div>
//               {/* <!-- Single List --> */}
//               {/* <!-- Single List --> */}
//               <div className="mb-7">
//                 <p className="font-size-4 mb-0">Phone</p>
//                 <h5 className="font-size-4 font-weight-semibold mb-0">
//                   <a className="text-black-2 text-break" href="tel:+999565562">
//                     +999 565 562
//                   </a>
//                 </h5>
//               </div>
//               {/* <!-- Single List --> */}
//               {/* <!-- Single List --> */}
//               <div className="mb-7">
//                 <p className="font-size-4 mb-0">Website Linked</p>
//                 <h5 className="font-size-4 font-weight-semibold mb-0">
//                   <Link to="/#" className="text-break">
//                     www.nameac.com
//                   </Link>
//                 </h5>
//               </div>
//               {/* <!-- Single List --> */}
//             </div>
//             {/* <!-- Bottom End --> */}
//           </div>
//         </div>
//       </div>

//       {/* <!-- Sidebar End --> */}
//     </>
//   );
// };

// export default Sidebar;

import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import network from "../../../constants/Network";
import GlobalContext from "../../context/GlobalContext";

import imgP from "../../assets/image/l3/png/pro-img.png";

const ProfileSidebar = ({ user }) => {

  const gContext = useContext(GlobalContext);

  const imageBaseUrl = `${network.serverip}/images/`;

  const styles = ProfileSidebarStyling();

  return (
    <>
      <div>
        <div className="pl-lg-5">
          <div className="bg-white shadow-9 rounded-4">
            <div className="px-5 py-11 text-center border-bottom border-mercury">
              {/* <Link to="/#"
                className="mb-4"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  src={user && user.profile && user.profile.image ? imageBaseUrl + user.profile.image : ""}
                  className="circle-54"
                  alt=""
                />
              </Link> */}
              <button
                className="mb-4"
                onClick={() => gContext.toggleImageViewModal(user?.profile?.image)}
                style={ styles.imageViewButton }
              >
                <img
                  src={user?.profile?.image ? imageBaseUrl + user.profile.image : imageBaseUrl + "profile_default_photo.png"}
                  className="circle-54"
                  alt=""
                />
              </button>
              <h4 className="mb-0"
              >
                <p
                  className="text-black-2 font-size-6 font-weight-semibold"
                >
                  {user?.name}
                </p>
              </h4>
              <p className="mb-8">
                <p className="text-gray font-size-4">
                  {user?.roleforapp?.name}
                </p>
              </p>

              {/* <div className="icon-link d-flex align-items-center justify-content-center flex-wrap">
                <Link
                  to="/#"
                  className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                >
                  <i className="fab fa-linkedin-in"></i>
                </Link>
                <Link
                  to="/#"
                  className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                >
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link
                  to="/#"
                  className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                >
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link
                  to="/#"
                  className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                >
                  <i className="fab fa-dribbble"></i>
                </Link>
                <Link
                  to="/#"
                  className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                >
                  <i className="fab fa-behance"></i>
                </Link>
              </div> */}

            </div>

            <div className="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">
              <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>

              {(user && (user?.profile?.street_address || user?.profile?.city || user?.profile?.state || user?.profile?.country || user?.profile?.pin_code)) ? (
                <div className="mb-7">
                  {/* <p className="font-size-4 font-weight-semibold mb-0">Location</p> */}
                  <div className="row d-flex align-items-start">
                    <div className="col-1 d-flex justify-content-start">
                      <p className="font-size-4 mb-0">
                        <i className="fas fa-map-marker-alt text-success"></i>
                      </p>
                    </div>
                    <div className="col-10 d-flex justify-content-start">
                      <p className="font-size-4 mb-0 text-black-2">
                        <span>{user?.profile?.street_address}</span>
                        <br />
                        <span>
                          {user?.profile?.city}
                          {user?.profile?.state ? `, ${user?.profile?.state}` : ''}
                        </span>
                        <br />
                        <span>{user?.profile?.country}</span>
                        <br />
                        <span>{user?.profile?.pin_code}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {user && user?.email &&
                <div className="mb-7">
                  <div className="row d-flex align-items-start">
                    <div className="col-1 d-flex justify-content-start">
                      <p className="font-size-4 mb-0">
                        <i className="fas fa-envelope text-success"></i>
                      </p>
                    </div>
                    <div className="col-10 d-flex justify-content-start">
                      <p
                        className="font-size-4 mb-0 text-black-2"
                      >
                        {user?.email}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {user && user?.mobile_number &&
                <div className="mb-7">
                  <div className="row d-flex align-items-start">
                    <div className="col-1 d-flex justify-content-start">
                      <p className="font-size-4 mb-0">
                        <i className="fas fa-phone-alt text-success"></i>
                      </p>
                    </div>
                    <div className="col-10 d-flex justify-content-start">
                      <p
                        className="font-size-4 mb-0 text-black-2"
                      >
                        {user?.mobile_number}
                      </p>
                    </div>
                  </div>
                </div>
              }

              <h5 className="text-black-2 mb-8 mt-15 font-size-5">Personal Details</h5>

              {(user && (user?.profile?.gender)) ? (
                <div className="mb-7">
                  <div className="row d-flex align-items-start">
                    <div className="col-1 d-flex justify-content-start">
                      <p className="font-size-4 mb-0">
                        <i className="fas fa-user-alt text-success"></i>
                      </p>
                    </div>
                    <div className="col-10 d-flex justify-content-start">
                      <p className="font-size-4 mb-0 text-black-2">
                        <span>{`Gender: ${user?.profile?.gender}`}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* <div className="mb-7">
                <p className="font-size-4 mb-0">Website Linked</p>
                <h5 className="font-size-4 font-weight-semibold mb-0">
                  <Link to="/#" className="text-break">
                    www.nameac.com
                  </Link>
                </h5>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSidebar;

function ProfileSidebarStyling() {
  const styles = {
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },

  };

  return styles;
}


