import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import network from "../../../constants/Network";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalImageView = (props) => {

  const gContext = useContext(GlobalContext);


  const handleClose = () => {
    gContext.toggleImageViewModal('');
  };

  const imageBaseUrl = `${network.serverip}/images/`;

  const styles = ModalImageViewStyling();

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.imageViewModalVisible}
      onHide={gContext.toggleImageViewModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="d-flex justify-content-center align-items-center" style={styles.modalContainer}>
          <img
            src={gContext?.imageToDisplay ? imageBaseUrl + gContext.imageToDisplay : ""}
            alt="Modal Display"
            style={styles.image}
          />
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalImageView;

function ModalImageViewStyling() {
  const styles = {
    modalContainer: {
      padding: '20px',
      textAlign: 'center',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '80vh',
      objectFit: 'contain',
    },
  }
  return styles;
}