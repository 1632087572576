import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import axiosConfig from '../../axiosConfig';
import Logo from "../Logo";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalSignUpSelection = (props) => {

  const gContext = useContext(GlobalContext);
  const handleClose = () => {
    gContext.toggleSignUpSelectionModal();
  };

  const handleUserButtonClick = () => {
    gContext.toggleSignUpSelectionModal();
    gContext.toggleSignUpModal();
  };

  const handleHrButtonClick = () => {
    gContext.toggleSignUpSelectionModal();
    gContext.toggleHrSignUpModal();
  };

  // const [aboutData, setAboutData] = useState({ title: "", image: "" });
  // useEffect(() => {
  //   axiosConfig.get('/about')
  //     .then(response => {
  //       if (response.data.success) {
  //         console.log(response.data.data);
  //         setAboutData(response.data.data);

  //       } else {
  //         console.error('Failed to fetch  text');
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error fetching  text:', error);
  //     });
  // }, []);

  const [aboutData, setAboutData] = useState({ title: "", image: "" });
  useEffect(() => {
    axiosConfig.get('/slider')
      .then(response => {
        if (response.data.success) {
          console.log(response.data.data);
          setAboutData(response.data.data);

        } else {
          console.error('Failed to fetch  text');
        }
      })
      .catch(error => {
        console.error('Error fetching  text:', error);
      });
  }, []);

  return (
    <ModalStyled
      {...props}
      size="xl"
      centered
      show={gContext.signUpSelectionModalVisible}
      onHide={gContext.toggleSignUpSelectionModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="row d-flex justify-content-center">
          <div className="col-6 d-none d-lg-flex align-items-end">
            <img
              src={aboutData.image}
              alt=""
              className="w-100 rounded overflow-hidden"
            />
          </div>
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 d-flex justify-content-center pt-7">
                <p className="text-primary text-center" style={{ fontSize: '1.5rem', marginBottom: '-2rem', marginTop: '3.5rem' }}>
                  Welcome to
                </p>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <Logo white={gContext.header.theme === "dark"} />
              </div>
              <div className="col-12 d-flex justify-content-center pb-7" style={{ marginTop: '5rem', marginBottom: '3.5rem' }}>
                <div className="row d-flex justify-content-center p-5">
                  <div className="col-7 d-flex justify-content-center pt-5">
                    <button
                      className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase font-weight-light"
                      style={{ fontSize: '0.75rem' }}
                      onClick={handleUserButtonClick}
                    >
                      I AM LOOKING FOR WORK
                    </button>
                  </div>
                  <div className="col-12 d-flex justify-content-center text-uppercase pt-3" style={{ fontSize: '0.75rem' }}>
                    OR
                  </div>
                  <div className="col-7 d-flex justify-content-center pt-2">
                    <button
                      onClick={handleHrButtonClick}
                      className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase font-weight-light"
                      style={{ fontSize: '0.75rem' }}
                    >
                      I AM LOOKING TO HIRE PEOPLE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalSignUpSelection;
