exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-candidate-profile-2-js": () => import("./../../../src/pages/candidate-profile-2.js" /* webpackChunkName: "component---src-pages-candidate-profile-2-js" */),
  "component---src-pages-candidate-profile-js": () => import("./../../../src/pages/candidate-profile.js" /* webpackChunkName: "component---src-pages-candidate-profile-js" */),
  "component---src-pages-category-wise-joblisting-grid-js": () => import("./../../../src/pages/category-wise-joblisting-grid.js" /* webpackChunkName: "component---src-pages-category-wise-joblisting-grid-js" */),
  "component---src-pages-category-wise-joblisting-js": () => import("./../../../src/pages/category-wise-joblisting.js" /* webpackChunkName: "component---src-pages-category-wise-joblisting-js" */),
  "component---src-pages-company-profile-js": () => import("./../../../src/pages/company-profile.js" /* webpackChunkName: "component---src-pages-company-profile-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-applicants-js": () => import("./../../../src/pages/dashboard-applicants.js" /* webpackChunkName: "component---src-pages-dashboard-applicants-js" */),
  "component---src-pages-dashboard-create-job-js": () => import("./../../../src/pages/dashboard-create-job.js" /* webpackChunkName: "component---src-pages-dashboard-create-job-js" */),
  "component---src-pages-dashboard-job-edit-js": () => import("./../../../src/pages/dashboard-job-edit.js" /* webpackChunkName: "component---src-pages-dashboard-job-edit-js" */),
  "component---src-pages-dashboard-jobs-js": () => import("./../../../src/pages/dashboard-jobs.js" /* webpackChunkName: "component---src-pages-dashboard-jobs-js" */),
  "component---src-pages-dashboard-main-js": () => import("./../../../src/pages/dashboard-main.js" /* webpackChunkName: "component---src-pages-dashboard-main-js" */),
  "component---src-pages-dashboard-settings-js": () => import("./../../../src/pages/dashboard-settings.js" /* webpackChunkName: "component---src-pages-dashboard-settings-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-featured-joblisting-grid-js": () => import("./../../../src/pages/featured-joblisting-grid.js" /* webpackChunkName: "component---src-pages-featured-joblisting-grid-js" */),
  "component---src-pages-featured-joblisting-js": () => import("./../../../src/pages/featured-joblisting.js" /* webpackChunkName: "component---src-pages-featured-joblisting-js" */),
  "component---src-pages-hr-profile-edit-js": () => import("./../../../src/pages/hr-profile-edit.js" /* webpackChunkName: "component---src-pages-hr-profile-edit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-apply-js": () => import("./../../../src/pages/job-apply.js" /* webpackChunkName: "component---src-pages-job-apply-js" */),
  "component---src-pages-job-details-js": () => import("./../../../src/pages/job-details.js" /* webpackChunkName: "component---src-pages-job-details-js" */),
  "component---src-pages-joblisting-grid-js": () => import("./../../../src/pages/joblisting-grid.js" /* webpackChunkName: "component---src-pages-joblisting-grid-js" */),
  "component---src-pages-joblisting-js": () => import("./../../../src/pages/joblisting.js" /* webpackChunkName: "component---src-pages-joblisting-js" */),
  "component---src-pages-landing-2-js": () => import("./../../../src/pages/landing-2.js" /* webpackChunkName: "component---src-pages-landing-2-js" */),
  "component---src-pages-landing-3-js": () => import("./../../../src/pages/landing-3.js" /* webpackChunkName: "component---src-pages-landing-3-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-profile-details-view-js": () => import("./../../../src/pages/profile-details-view.js" /* webpackChunkName: "component---src-pages-profile-details-view-js" */),
  "component---src-pages-profilelisting-grid-js": () => import("./../../../src/pages/profilelisting-grid.js" /* webpackChunkName: "component---src-pages-profilelisting-grid-js" */),
  "component---src-pages-profilelisting-js": () => import("./../../../src/pages/profilelisting.js" /* webpackChunkName: "component---src-pages-profilelisting-js" */),
  "component---src-pages-recent-profilelisting-grid-js": () => import("./../../../src/pages/recent-profilelisting-grid.js" /* webpackChunkName: "component---src-pages-recent-profilelisting-grid-js" */),
  "component---src-pages-recent-profilelisting-js": () => import("./../../../src/pages/recent-profilelisting.js" /* webpackChunkName: "component---src-pages-recent-profilelisting-js" */),
  "component---src-pages-recommended-joblisting-grid-js": () => import("./../../../src/pages/recommended-joblisting-grid.js" /* webpackChunkName: "component---src-pages-recommended-joblisting-grid-js" */),
  "component---src-pages-recommended-joblisting-js": () => import("./../../../src/pages/recommended-joblisting.js" /* webpackChunkName: "component---src-pages-recommended-joblisting-js" */),
  "component---src-pages-recommended-profilelisting-grid-js": () => import("./../../../src/pages/recommended-profilelisting-grid.js" /* webpackChunkName: "component---src-pages-recommended-profilelisting-grid-js" */),
  "component---src-pages-recommended-profilelisting-js": () => import("./../../../src/pages/recommended-profilelisting.js" /* webpackChunkName: "component---src-pages-recommended-profilelisting-js" */),
  "component---src-pages-saved-joblisting-grid-js": () => import("./../../../src/pages/saved-joblisting-grid.js" /* webpackChunkName: "component---src-pages-saved-joblisting-grid-js" */),
  "component---src-pages-saved-joblisting-js": () => import("./../../../src/pages/saved-joblisting.js" /* webpackChunkName: "component---src-pages-saved-joblisting-js" */),
  "component---src-pages-search-grid-js": () => import("./../../../src/pages/search-grid.js" /* webpackChunkName: "component---src-pages-search-grid-js" */),
  "component---src-pages-search-list-2-js": () => import("./../../../src/pages/search-list-2.js" /* webpackChunkName: "component---src-pages-search-list-2-js" */),
  "component---src-pages-search-list-js": () => import("./../../../src/pages/search-list.js" /* webpackChunkName: "component---src-pages-search-list-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-user-education-js": () => import("./../../../src/pages/user-education.js" /* webpackChunkName: "component---src-pages-user-education-js" */),
  "component---src-pages-user-experience-js": () => import("./../../../src/pages/user-experience.js" /* webpackChunkName: "component---src-pages-user-experience-js" */),
  "component---src-pages-user-procedure-js": () => import("./../../../src/pages/user-procedure.js" /* webpackChunkName: "component---src-pages-user-procedure-js" */),
  "component---src-pages-user-profile-details-view-js": () => import("./../../../src/pages/user-profile-details-view.js" /* webpackChunkName: "component---src-pages-user-profile-details-view-js" */),
  "component---src-pages-user-profile-edit-js": () => import("./../../../src/pages/user-profile-edit.js" /* webpackChunkName: "component---src-pages-user-profile-edit-js" */),
  "component---src-pages-user-qualification-js": () => import("./../../../src/pages/user-qualification.js" /* webpackChunkName: "component---src-pages-user-qualification-js" */),
  "component---src-pages-user-skill-js": () => import("./../../../src/pages/user-skill.js" /* webpackChunkName: "component---src-pages-user-skill-js" */)
}

