import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";

import styled, { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";

import Header from "../Header";
import Footer from "../Footer";

import SidebarDashboard from "../SidebarDashboard";
import ModalVideo from "../ModalVideo";
import ModalApplication from "../ModalApplication";
// import ModalSignIn from "../ModalSignIn";
// import ModalSignUp from "../ModalSignUp";
import { ModalSignIn, ModalOtpVerify, ModalMobNumVerify, ModalForgotPassword } from "../ModalSignIn";
import { ModalSignUp, ModalHrSignUp, ModalSignUpSelection } from "../ModalSignUp";
import { ModalEmailUpdate } from "../ModalProfileUpdate";
import { ModalImageView } from "../ModalView";

import GlobalContext from "../../context/GlobalContext";

import GlobalStyle from "../../utils/globalStyle";

import imgFavicon from "../../assets/favicon-32x32.png";

import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/fontawesome-5/css/all.css";

import "../../scss/bootstrap.scss";
import "../../scss/main.scss";
import TawkToScript from "../../components/TawkTo/TawkToScript";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { FaWindowMinimize, FaTimes } from 'react-icons/fa';
import userimage from '../../assets/image/user.png';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Avatar,
  Conversation,
  ConversationList,
  MessageGroup,
  MessageSeparator,
  AttachmentButton,
  SendButton,
  Status,
  ConversationHeader,
  VoiceCallButton,
  VideoCallButton,
  InfoButton,
  Sidebar,
  Search,
  lillyIco,
  joeIco,
  emilyIco,
  kaiIco,
  akaneIco,
  eliotIco,
  zoeIco,
  patrikIco,
  EllipsisButton
} from "@chatscope/chat-ui-kit-react";


import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../../utils";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;


// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext);
   const { isChatVisible, handleOpenChat, handleClose } = useContext(GlobalContext);
  const [visibleLoader, setVisibleLoader] = useState(true);

  useLayoutEffect(() => {
    AOS.init({ once: true });
    setVisibleLoader(false);
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
  }, [gContext]);
  const tawkPropertyId = "bc6d6e5bd130dd36bae98b8e9e51e072bebe1e8c"; // replace with actual ID
  const tawkWidgetId = "1ibifnj3f"; // replace with actual ID if different

  const [isMinimized, setIsMinimized] = useState(false);
  
  console.log("isMinimized:", isMinimized);
  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  console.log("isChatVisible:", isChatVisible);

  

  
  const ChatUI = isChatVisible && (
                                      <div
                                        style={{
                                          position: 'fixed',
                                          height: isMinimized ? '60px' : '500px',
                                          left: '5px',
                                          bottom: '0',
                                          zIndex: '1000',
                                        }}
                                      >
                                        <MainContainer>
                                          <ChatContainer>
                                            {/* Conversation Header */}
                                            <ConversationHeader>
                                              <Avatar src={userimage} name="Zoe" />
                                              <ConversationHeader.Content userName="Zoe" />
                                              <ConversationHeader.Actions>
                                                <VoiceCallButton color="#184169" />
                                                <FaWindowMinimize
                                                  size={20}
                                                  onClick={handleMinimize}
                                                  color="#184169"
                                                />
                                                <FaTimes
                                                  size={20}
                                                  onClick={handleClose}
                                                  color="#184169"
                                                />
                                              </ConversationHeader.Actions>
                                            </ConversationHeader>

                                            {/* Message List */}
                                            <MessageList
                                              typingIndicator={<TypingIndicator content="Zoe is typing" />}
                                              style={{
                                                display: isMinimized ? 'none' : 'block',
                                                height: 'calc(100% - 120px)', // Account for header and input
                                              }}
                                            >
                                              {/* Messages */}
                                              <Message
                                                model={{
                                                  message: "Hello my friend",
                                                  sentTime: "15 mins ago",
                                                  sender: "Zoe",
                                                  direction: "incoming",
                                                  position: "first",
                                                }}
                                                avatarSpacer
                                              />
                                              <Message
                                                model={{
                                                  message: "Hello my friend",
                                                  sentTime: "15 mins ago",
                                                  sender: "Zoe",
                                                  direction: "incoming",
                                                  position: "last",
                                                }}
                                              >
                                                <Avatar src={userimage} name="Zoe" />
                                              </Message>

                                              {/* Message Separator */}
                                              <MessageSeparator content="Saturday, 30 November 2019" />

                                              {/* Additional Messages */}
                                              <Message
                                                model={{
                                                  message: "Hello my friend",
                                                  sentTime: "15 mins ago",
                                                  sender: "Zoe",
                                                  direction: "incoming",
                                                  position: "single",
                                                }}
                                              >
                                                <Avatar src={userimage} name="Zoe" />
                                              </Message>
                                              <Message
                                                model={{
                                                  message: "Hello my friend",
                                                  sentTime: "15 mins ago",
                                                  sender: "Patrik",
                                                  direction: "outgoing",
                                                  position: "single",
                                                }}
                                                avatarSpacer
                                              />
                                              <Message
                                                model={{
                                                  message: "Hello my friend",
                                                  sentTime: "15 mins ago",
                                                  sender: "Zoe",
                                                  direction: "incoming",
                                                  position: "first",
                                                }}
                                                avatarSpacer
                                              />
                                              <Message
                                                model={{
                                                  message: "Hello my friend",
                                                  sentTime: "15 mins ago",
                                                  sender: "Zoe",
                                                  direction: "incoming",
                                                  position: "last",
                                                }}
                                              >
                                                <Avatar src={userimage} name="Zoe" />
                                              </Message>

                                              {/* Outgoing Messages */}
                                              {["first", "normal", "last"].map((position, index) => (
                                                <Message
                                                  key={index}
                                                  model={{
                                                    message: "Hello my friend",
                                                    sentTime: "15 mins ago",
                                                    sender: "Patrik",
                                                    direction: "outgoing",
                                                    position,
                                                  }}
                                                />
                                              ))}
                                            </MessageList>

                                            {/* Message Input */}
                                            <MessageInput
                                              placeholder="Type message here"
                                              style={{
                                                display: isMinimized ? 'none' : 'block',
                                              }}
                                            />
                                          </ChatContainer>
                                        </MainContainer>
                                      </div>

  );

  if (pageContext.layout === "bare") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>Stackle - Medical Jobs & Profiles</title>
            <link rel="icon" type="image/png" href={imgFavicon} />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            {children}
            <TawkToScript />
          </div>
          {ChatUI}
          <ModalVideo />
          <ModalApplication />
          <ModalSignIn />
          <ModalMobNumVerify />
          <ModalOtpVerify />
          <ModalSignUp />
          <ModalHrSignUp />
          <ModalSignUpSelection />
          <ModalForgotPassword />
          <ModalEmailUpdate />
          <ModalImageView />
        </div>
      </ThemeProvider>
    );
  }

  if (pageContext.layout === "dashboard") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>Stackle - Medical Jobs & Profiles</title>
            <link rel="icon" type="image/png" href={imgFavicon} />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div
            className="site-wrapper overflow-hidden bg-default-2"
            ref={eleRef}
          >
            <Header isDark={gContext.headerDark} />
            <SidebarDashboard />
            {children}
          </div>
          {ChatUI}
          <ModalVideo />
          <ModalApplication />
          <ModalSignIn />
          <ModalMobNumVerify />
          <ModalOtpVerify />
          <ModalSignUp />
          <ModalHrSignUp />
          <ModalSignUpSelection />
          <ModalForgotPassword />
          <ModalEmailUpdate />
          <ModalImageView />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />
          <Helmet>
            <title>Stackle - Medical Jobs & Profiles</title>
            <link rel="icon" type="image/png" href={imgFavicon} />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? "" : "inActive"} />
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            <Header isDark={gContext.headerDark} />
            {children}

            <Footer isDark={gContext.footerDark} />
            <TawkToScript />
          </div>
          {ChatUI}
          <ModalVideo />
          <ModalApplication />
          <ModalSignIn />
          <ModalMobNumVerify />
          <ModalOtpVerify />
          <ModalSignUp />
          <ModalHrSignUp />
          <ModalSignUpSelection />
          <ModalForgotPassword />
          <ModalEmailUpdate />
          <ModalImageView />
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
